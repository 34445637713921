import { listToDict } from '@og-pro/shared-config/helpers';

export const form = 'requisitionCreateConfirmation';

const fields = [
    'expectedPurchaseOrderDate',
    'requestor_id',
    'request_type_id',
    'review_group_id',
    'creator_id',
    'fiscalPeriod',
    'fiscal_period_tag_id',
    'fiscalPeriodObject',
];

export const fieldNames = listToDict(fields);
