import {
    Add as AddIcon,
    AltRoute as AltRouteIcon,
    MultipleStop as MultipleStopIcon,
} from '@mui/icons-material';
import {
    Button,
    DropdownMenu,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@og-pro/ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { createReviewSequence } from '../../../../actions/reviewSequences';
import { getTemporarySingleRequestType } from '../../../../selectors/govApp';

export const ReviewSequenceCreateButton = ({ routingHandler, size }) => {
    const [creating, setCreating] = useState(false);
    const [createError, setCreateError] = useState(null);
    const dispatch = useDispatch();

    const enableExceptionSequences = useFlags(FLAGS.ENABLE_EXCEPTION_SEQUENCES);
    const requestType = useSelector(getTemporarySingleRequestType);

    const createHandler = (createData) => {
        setCreating(true);
        setCreateError(null);
        dispatch(createReviewSequence(requestType.id, createData))
            .then((reviewSequence) => {
                setCreating(false);
                routingHandler(reviewSequence.id);
            })
            .catch((error) => {
                setCreating(false);
                setCreateError(error.message);
            });
    };

    const defaultSequenceHandler = () => createHandler();

    const exceptionSequenceHandler = () => createHandler({ isException: true });

    if (enableExceptionSequences) {
        // TODO: Incorpoate size and zero state
        return (
            <>
                <DropdownMenu
                    color="primary"
                    disabled={creating}
                    label="New Sequence"
                    size={size}
                    startIcon={<AddIcon />}
                    variant="contained"
                >
                    <ListItem disablePadding divider>
                        <ListItemButton disabled={creating} onClick={defaultSequenceHandler}>
                            <ListItemIcon>
                                <MultipleStopIcon />
                            </ListItemIcon>
                            <ListItemText
                                id="review-sequence-create-dropdown-regular"
                                primary="Regular Sequence"
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton
                            disabled={creating}
                            onClick={() => exceptionSequenceHandler()}
                        >
                            <ListItemIcon>
                                <AltRouteIcon />
                            </ListItemIcon>
                            <ListItemText
                                id="review-sequence-create-dropdown-exception"
                                primary="Exception Sequence"
                            />
                        </ListItemButton>
                    </ListItem>
                </DropdownMenu>
                {createError && (
                    <div className="error-block">
                        <Typography component="span" variant="bodySmall">
                            {createError}
                        </Typography>
                    </div>
                )}
            </>
        );
    }

    return (
        <>
            <Button
                color="primary"
                disabled={creating}
                onClick={defaultSequenceHandler}
                qaTag="reviewSequence-create"
                size={size}
                startIcon={<AddIcon />}
                variant="contained"
            >
                New Sequence
            </Button>
            {createError && (
                <div className="error-block">
                    <Typography component="span" variant="bodySmall">
                        {createError}
                    </Typography>
                </div>
            )}
        </>
    );
};

ReviewSequenceCreateButton.propTypes = {
    routingHandler: PropTypes.func.isRequired,
    size: PropTypes.string,
};
