import { createSelector } from 'reselect';

const getRawGovernmentRequestTypes = (state) => state.adminGovernment.get('requestTypes');

// REQUEST TYPES TODO: Remove when we have a way to create request types
export const getTemporarySingleRequestType = createSelector(
    [getRawGovernmentRequestTypes],
    (rawRequestTypes) => {
        const requestTypes = rawRequestTypes ? rawRequestTypes.toJS() : [];
        return requestTypes[0];
    }
);
