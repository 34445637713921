import { createSelector } from 'reselect';

import { getTemporarySingleRequestType } from '../../../selectors/govApp';

export const getRequisitionGroupsPath = (props) => {
    const { governmentId } = props.params;
    return `/governments/${governmentId}/requisition-groups`;
};

export const getReviewSequencesPath = createSelector(
    [getTemporarySingleRequestType, (_state, props) => getRequisitionGroupsPath(props)],
    (requestType, requisitionGroupsPath) => {
        return `${requisitionGroupsPath}/${requestType.id}/sequences`;
    }
);
