import PropTypes from 'prop-types';
import React from 'react';

import { approvalStatusTypes } from '@og-pro/shared-config/requisitions';

import { getPrimaryAndSecondaryApprovers } from './helpers';
import { StepApprovalGroup } from '../SequenceStep/StepApprovalGroup';

export const MultiPositionApproval = ({ minPositionReviews, stepPositionApprovals }) => {
    const approvers = stepPositionApprovals.map((stepPositionApproval) => {
        const { position, stepPositionApprovers } = stepPositionApproval;
        const { primaryApprovers, secondaryApprovers } =
            getPrimaryAndSecondaryApprovers(stepPositionApprovers);

        return {
            ...(primaryApprovers[0] || secondaryApprovers[0]).user,
            positionName: position.name,
        };
    });

    const approvalsReceived = stepPositionApprovals.filter(
        ({ status }) => status === approvalStatusTypes.APPROVED
    ).length;
    const approvalsNeeded = minPositionReviews - approvalsReceived;

    return (
        <StepApprovalGroup
            approvers={approvers}
            hasPosition
            name={`${approvalsNeeded} of ${minPositionReviews} Approvals Needed`}
        />
    );
};

MultiPositionApproval.propTypes = {
    minPositionReviews: PropTypes.number.isRequired,
    stepPositionApprovals: PropTypes.arrayOf(
        PropTypes.shape({
            position: PropTypes.shape({
                name: PropTypes.string.isRequired,
            }),
            stepPositionApprovers: PropTypes.array.isRequired,
        })
    ).isRequired,
};
