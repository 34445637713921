import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@og-pro/ui';

import { Signature } from '../../../components/Signature';
import { getReviewProjectJS as getGovernmentProjectJS } from '../selectors';

export const SignaturesList = () => {
    const project = useSelector(getGovernmentProjectJS);
    const styles = require('./index.scss');

    return (
        <div className="row">
            <div className="col-xs-12 col-md-8 col-md-offset-2">
                <Box className={styles.container}>
                    <Typography align="center" variant="h2">
                        Signatures
                    </Typography>
                    <Box mt={3}>
                        <Signature signatures={project.signatures} />
                    </Box>
                </Box>
            </div>
        </div>
    );
};
