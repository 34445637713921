import React from 'react';
import PropTypes from 'prop-types';
import {
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';

export const CustomFieldsTable = ({ cols, rows }) => {
    return (
        <TableContainer sx={{ margin: 0, width: 'auto' }}>
            <MuiTable aria-label="custom-fields-table">
                <TableHead>
                    <TableRow>
                        {cols.map((col) => (
                            <TableCell align={col.alignment} key={col.title}>
                                <Typography fontWeight="500" variant={col.style}>
                                    {col.title}
                                </Typography>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow hover key={index}>
                            <TableCell align={cols[0].alignment}>
                                <Typography fontWeight={400} variant="h5">
                                    {row.fieldName}
                                </Typography>
                            </TableCell>
                            <TableCell align={cols[1].alignment}>
                                <Typography fontWeight={400} variant="h5">
                                    {row.reportName}
                                </Typography>
                            </TableCell>
                            <TableCell align={cols[2].alignment}>
                                <Typography fontWeight={400} variant="h5">
                                    {row.lastUpdated}
                                </Typography>
                            </TableCell>
                            <TableCell align={cols[3].alignment}>
                                <Typography fontWeight={400} variant="h5">
                                    {row.status}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
};

CustomFieldsTable.propTypes = {
    cols: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            alignment: PropTypes.string.isRequired,
            style: PropTypes.string.isRequired,
        })
    ).isRequired,
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            fieldName: PropTypes.string.isRequired,
            reportName: PropTypes.string,
            lastUpdated: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
        })
    ).isRequired,
};
