import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@og-pro/ui';

import { fieldTypes } from '@og-pro/shared-config/signatures';

import { SignatureDocx } from './SignatureDocx';
import { signaturesBlocksToColumns } from '../../helpers/signatures';

const FieldValue = ({ field }) => {
    if (!field.value) {
        return null;
    }

    switch (field.type) {
        case fieldTypes.DATE:
            return new Date(field.value).toLocaleDateString();
        case fieldTypes.STAMP:
            return <Box height={field.value}>&nbsp;</Box>;
        default:
            return field.value;
    }
};

FieldValue.propTypes = {
    field: PropTypes.shape({
        type: PropTypes.string.isRequired,
        value: PropTypes.oneOfType(PropTypes.string, PropTypes.number, PropTypes.bool),
    }).isRequired,
};

const SignatureBlock = ({ block }) => {
    if (block.styling === 'header') {
        return (
            <Box
                dangerouslySetInnerHTML={{
                    __html: block.fields[0].value,
                }}
            />
        );
    }

    return (
        <Box>
            {block.fields?.map((field) => {
                return (
                    <Box key={field.label} mt={3}>
                        <Box borderBottom="1px solid #333" flex={4} minHeight="28px" pb={0.5}>
                            <FieldValue field={field} />
                        </Box>
                        <Box flex={1} fontSize="12px" mr={1}>
                            <b>{field.label?.toUpperCase() || ''}</b>
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

SignatureBlock.propTypes = {
    block: PropTypes.shape({
        fields: PropTypes.array.isRequired,
        styling: PropTypes.oneOf(['header', 'default']).isRequired,
    }).isRequired,
};

export const Signature = (props) => {
    if (props.isDocx) {
        return <SignatureDocx {...props} />;
    }

    const { signatures } = props;

    return (
        <Box px={2}>
            {signatures
                .filter((s) => !s.isHiddenByLogic)
                .map((rawSignature) => {
                    const signature = signaturesBlocksToColumns(rawSignature);

                    const left = signature.signaturesBlocks[0]?.filter((b) => !b.isHiddenByLogic);
                    const right = signature.signaturesBlocks[1]?.filter((b) => !b.isHiddenByLogic);

                    return (
                        <>
                            {!!signature.introduction && (
                                <Box
                                    dangerouslySetInnerHTML={{ __html: signature.introduction }}
                                    mb={3}
                                />
                            )}

                            <Box>
                                <Box display="flex" pb={6}>
                                    <Box flex={1} pr={4}>
                                        {(left || []).map((block, i) => {
                                            return (
                                                <Box key={i} pb={6}>
                                                    <SignatureBlock block={block} />
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                    <Box flex={1} pl={4}>
                                        {(right || []).map((block, i) => {
                                            return (
                                                <Box key={i} pb={6}>
                                                    <SignatureBlock block={block} />
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                            {signature.blankBlocks.map((block, i) => {
                                return (
                                    <Box fontStyle="italic" fontWeight="bold" key={i} pb={3}>
                                        Notary
                                    </Box>
                                );
                            })}
                            {!!signature.footer && (
                                <Box dangerouslySetInnerHTML={{ __html: signature.footer }} />
                            )}
                        </>
                    );
                })}
        </Box>
    );
};

Signature.propTypes = {
    isDocx: PropTypes.bool,
    signatures: PropTypes.array.isRequired,
};
