import { io } from 'socket.io-client';

let checklistSocketInstance;
let globalSocketInstance;
let projectSocketInstance;
let requisitionSocketInstance;
let reverseAuctionSocketInstance;
let userSocketInstance;
let vendorProposalSocketInstance;

// Do not connect to socket.io in supportboard (there is no sync server to connect to) or on the server
if (process.env.APP_NAME !== 'support' && !process.env.SERVER) {
    // Sockets that should auto-connect
    globalSocketInstance = io(process.env.SOCKET_HOST, {
        withCredentials: true,
    });
    userSocketInstance = io(`${process.env.SOCKET_HOST}/users`, {
        withCredentials: true,
    });

    // Sockets that should connect given a specific context. In this case, they are handled in their given parent components.
    checklistSocketInstance = io(`${process.env.SOCKET_HOST}/checklists`, { 
        autoConnect: false,
        withCredentials: true });
    projectSocketInstance = io(`${process.env.SOCKET_HOST}/projects`, { 
        autoConnect: false,
        withCredentials: true 
    });
    requisitionSocketInstance = io(`${process.env.SOCKET_HOST}/requisitions`, {
        autoConnect: false,
        withCredentials: true,
    });
    reverseAuctionSocketInstance = io(`${process.env.SOCKET_HOST}/reverseAuctions`, {
        autoConnect: false,
        withCredentials: true,
    });
    vendorProposalSocketInstance = io(`${process.env.SOCKET_HOST}/vendorProposals`, {
        autoConnect: false,
        withCredentials: true,
    });
}

export const checklistSocket = checklistSocketInstance;
export const globalSocket = globalSocketInstance;
export const projectSocket = projectSocketInstance;
export const requisitionSocket = requisitionSocketInstance;
export const reverseAuctionSocket = reverseAuctionSocketInstance;
export const userSocket = userSocketInstance;
export const vendorProposalSocket = vendorProposalSocketInstance;
