import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formValueSelector, getFormSyncErrors } from 'redux-form';

import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';
import { Box, Tooltip, Typography } from '@og-pro/ui';
import { tokens } from '@opengov/capital-style';

import { DraggableChecklistSection } from './DraggableChecklistSection';
import { ReviewContainer } from './ReviewContainer';
import { ReviewChecklistSection } from './Section';
import { ReviewChecklistSectionsContainer } from './SectionsContainer';
import { ProjectCreateV2FunctionsContext, ProjectCreateV2NavContext } from '../../context';
import { getSDv2WritingSections } from '../../../selectors';
import { buildTabRoute } from '../../../../../../helpers';
import { projectSectionsToNavSections } from '../../../../../../components/SDv2/helpers';
import { draggableSectionTypes } from './constants';

const { EXHIBITS, EXHIBIT_LIST, SIGNATURES } = draggableSectionTypes;

export const ContractReviewChecklist = () => {
    const styles = require('./index.scss');

    const signaturesTab = useFlags(FLAGS.ENABLE_SIGNATURES_TAB);
    const navigate = useNavigate();
    const projectSections = useSelector(getSDv2WritingSections);
    const { activeSectionId, setActiveSectionId } = useContext(ProjectCreateV2NavContext);
    const {
        form,
        location,
        project,
        project: { useManualNumbering },
    } = useContext(ProjectCreateV2FunctionsContext);

    const selector = formValueSelector(form);
    const showExhibitsListValue = useSelector((state) => selector(state, 'showExhibitsList'));
    const errorsSelectors = getFormSyncErrors(form);
    const formErrors = useSelector((state) => errorsSelectors(state));

    const buildRoute = buildTabRoute(location);
    const showingSignaturesTab = signaturesTab;
    const showSignaturesSection = !!project.signatures.length;
    const showExhibitsList = !!project.attachments.length && showExhibitsListValue;
    const showExhibitsSection = !!project.attachments.length;

    const mainDocumentSections = projectSectionsToNavSections({
        projectSections,
        active: activeSectionId,
        setActive: setActiveSectionId,
        useManualNumbering,
        showFormValidation: true,
        matchErrorToSection: (section) => {
            return formErrors?.sections && formErrors.sections[section.id];
        },
        hideEmptyDividers: true,
    });

    // TODO: account for order stored in DB
    const [draggableArray, setDraggableArray] = useState([
        {
            type: SIGNATURES,
            hidden: !showSignaturesSection,
        },
        {
            type: EXHIBIT_LIST,
            hidden: !showExhibitsList,
            sections: project.attachments
                ?.filter((attachment) => !attachment.isHiddenByLogic)
                .map((attachment) => ({
                    title: `${attachment.appendixId} - ${attachment.title}`,
                })),
        },
        {
            type: EXHIBITS,
            hidden: !showExhibitsSection,
            sections: project.attachments
                ?.filter((attachment) => !attachment.isHiddenByLogic)
                .map((attachment) => ({
                    title: `${attachment.appendixId} - ${attachment.title}`,
                })),
        },
    ]);

    useEffect(() => {
        setDraggableArray((prev) =>
            prev.map((draggable) => {
                if (draggable.type === EXHIBIT_LIST) {
                    return {
                        ...draggable,
                        hidden: !showExhibitsList,
                    };
                }
                return draggable;
            })
        );
    }, [showExhibitsList]);

    const moveDraggable = (from, to) =>
        setDraggableArray((prev) => {
            const newDraggableArray = [...prev];
            const [removed] = newDraggableArray.splice(from, 1);
            newDraggableArray.splice(to, 0, removed);
            return newDraggableArray;
        });

    const handleDragEnd = (result) => {
        const originLocation = result.source.index;
        const newLocation = result.destination ? result.destination.index : undefined;
        if (newLocation !== undefined && newLocation !== originLocation) {
            moveDraggable(originLocation, newLocation);
        }
    };

    return (
        <ReviewContainer
            description="This tab displays the order of things within your contract document. Check for errors and update the ordering before sending your document to be approved."
            onSubmit={() => {}}
            showingSignaturesTab={showingSignaturesTab}
            submitButtonText="Start Review Process"
            title="Review Checklist"
        >
            <Box mb={3}>
                <Box alignItems="center" display="flex" gap={1}>
                    <Typography variant="h3">Contract Properties</Typography>
                    <Tooltip title="Contract properties will not display in the web version of your contract or in the exported (and printable) version.">
                        <InfoOutlinedIcon
                            fontSize="small"
                            style={{ color: tokens.colors.colorGray700 }}
                        />
                    </Tooltip>
                </Box>
                <Box className={styles.sectionsContainer}>
                    <ReviewChecklistSection
                        incomplete={formErrors?.sections[1]}
                        onClick={() => navigate(buildRoute('project-properties'))}
                        section={{ title: 'Contract Information' }}
                    />
                    <ReviewChecklistSection
                        incompleteFields={
                            formErrors?.upfrontQuestions &&
                            formErrors?.upfrontQuestions.filter((e) => !!e).length
                        }
                        last
                        onClick={() =>
                            navigate(`${buildRoute('project-properties')}?activeSection=1`)
                        }
                        section={{ title: 'Set Up Questions' }}
                    />
                </Box>
            </Box>
            <Box mb={3}>
                <Typography variant="h3">Contract Package</Typography>
                <ReviewChecklistSectionsContainer
                    buildRoute={buildRoute}
                    sections={mainDocumentSections}
                    title="Main Document Outline"
                />
            </Box>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="contractPackageCompiler">
                    {(provided) => (
                        <Box ref={provided.innerRef} {...provided.droppableProps}>
                            {draggableArray
                                .filter((draggable) => !draggable.hidden)
                                .map((draggable, index) => (
                                    <DraggableChecklistSection
                                        buildRoute={buildRoute}
                                        canMoveDown={index < draggableArray.length - 1}
                                        canMoveUp={index > 0}
                                        draggable={draggable}
                                        formErrors={formErrors}
                                        index={index}
                                        key={draggable.type}
                                        move={moveDraggable}
                                    />
                                ))}
                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
            </DragDropContext>
        </ReviewContainer>
    );
};
