import { Box, Grid } from '@mui/material';
import React from 'react';

import { Typography } from '@og-pro/ui';

import { pageBodyStyles } from './styles';
import { RequisitionsCreateCustomFieldForm } from './Form/RequisitionsCreateCustomFieldForm';
import { RequisitionsCreateCustomFieldFormPreview } from './Form/RequisitionsCreateCustomFieldFormPreview';

export const RequisitionsCreateCustomFieldBody = () => {
    return (
        <Box sx={pageBodyStyles}>
            <Grid container spacing={4}>
                <Grid item md={4} sm={12}>
                    <Typography variant="h3">Preview</Typography>
                    <RequisitionsCreateCustomFieldFormPreview />
                </Grid>
                <Grid item md={8} sm={12}>
                    <Typography variant="h3">The Question</Typography>
                    <RequisitionsCreateCustomFieldForm />
                </Grid>
            </Grid>
        </Box>
    );
};
