import React, { useState } from 'react';

import { InputAdornment, TextField } from '@og-pro/ui';

import SearchIcon from '@mui/icons-material/Search';

import { useDebounce, useQueryParam } from '../../../../../../hooks';
import { qaTagPageName } from '../../constants';

export const RequisitionsListSearch = () => {
    const [searchValueParam, , setQueryParams] = useQueryParam('search');

    const [searchValue, setSearchValue] = useState(searchValueParam);

    const handleSearch = (value) => {
        setQueryParams({
            page: 1,
            search: value,
        });
    };

    const debounceSetSearchQuery = useDebounce(handleSearch);

    const handleChange = (event) => {
        setSearchValue(event.target.value);
        const newValue = event.target.value;
        debounceSetSearchQuery(newValue);
    };

    return (
        <TextField
            InputProps={{
                startAdornment: (
                    <InputAdornment aria-label="search icon" position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
                'aria-label': 'Search Requisitions',
            }}
            onChange={handleChange}
            placeholder="Search by number or description"
            qaTag={`${qaTagPageName}-search`}
            sx={{
                padding: 0,
                '& .MuiInputBase-root': {
                    margin: 0,
                    height: '34px',
                },
                minWidth: '30%',
            }}
            type="text"
            value={searchValue}
        />
    );
};
