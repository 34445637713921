import PropTypes from 'prop-types';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { Field } from 'redux-form';

import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';
import { Box, Button, Tooltip, Typography } from '@og-pro/ui';
import { tokens } from '@opengov/capital-style';

import { ReviewChecklistSection } from './Section';
import { ReviewChecklistSectionsContainer } from './SectionsContainer';
import { DragIcon, Toggle } from '../../../../../../components';
import { ContentBlock } from '../../../../../../components/SDv2/ContentBlock';
import { draggableSectionTypes } from './constants';

const { EXHIBITS, EXHIBIT_LIST, SIGNATURES } = draggableSectionTypes;

export const DraggableChecklistSection = ({
    buildRoute,
    canMoveDown,
    canMoveUp,
    draggable,
    formErrors,
    index,
    move,
}) => {
    const styles = require('./index.scss');
    const { navigate } = useNavigate();

    return (
        <Draggable draggableId={draggable.type} index={index} key={draggable.type}>
            {(draggableProvided) => {
                return (
                    <Box ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                        <ContentBlock className={styles.contentBlock}>
                            <ContentBlock.Main>
                                {draggable.type === SIGNATURES && (
                                    <ReviewChecklistSection
                                        incomplete={
                                            /* eslint-disable-line */ formErrors
                                                ?.standaloneSignatures._error
                                        }
                                        last
                                        onClick={() => navigate(buildRoute(SIGNATURES))}
                                        section={{ title: 'Signatures' }}
                                    />
                                )}
                                {draggable.type === EXHIBITS && (
                                    <ReviewChecklistSectionsContainer
                                        hideContainer
                                        hideStatus
                                        route={buildRoute('attachments')}
                                        sections={draggable.sections || []}
                                        title={
                                            <Box
                                                alignItems="center"
                                                className={styles.toggle}
                                                display="flex"
                                                justifyContent="space-between"
                                            >
                                                <Box>Exhibits</Box>
                                                <Field
                                                    component={Toggle}
                                                    leftLabel=""
                                                    name="showExhibitsList"
                                                    rightLabel={
                                                        <Box
                                                            alignItems="cemter"
                                                            display="flex"
                                                            gap={1}
                                                        >
                                                            <Typography variant="bodySmall">
                                                                Display Exhibit List
                                                            </Typography>
                                                            <Tooltip title="When turned on, an Exhibit List will be displayed before all uploaded exhibits.">
                                                                <InfoOutlinedIcon
                                                                    fontSize="small"
                                                                    style={{
                                                                        color: tokens.colors
                                                                            .colorGray700,
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </Box>
                                                    }
                                                    useOpenGovStyle
                                                />
                                            </Box>
                                        }
                                    />
                                )}
                                {draggable.type === EXHIBIT_LIST && (
                                    <ReviewChecklistSection
                                        hideStatus
                                        incomplete={
                                            /* eslint-disable-line */ formErrors
                                                ?.standaloneSignatures._error
                                        }
                                        last
                                        onClick={() => navigate(buildRoute(SIGNATURES))}
                                        section={{
                                            title: 'Exhibit List',
                                        }}
                                    >
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            gap={1}
                                            px={4}
                                            py={2}
                                        >
                                            {draggable.sections.map((section) => (
                                                <Box>{section.title}</Box>
                                            ))}
                                        </Box>
                                    </ReviewChecklistSection>
                                )}
                            </ContentBlock.Main>
                            <ContentBlock.ActionSidebar>
                                <ContentBlock.ButtonGroup>
                                    {canMoveUp && (
                                        <ContentBlock.Button>
                                            <Button
                                                bsStyle="link"
                                                onClick={() => move(index, index - 1)}
                                            >
                                                <i aria-hidden="true" className="fa fa-arrow-up" />
                                            </Button>
                                        </ContentBlock.Button>
                                    )}
                                    <ContentBlock.Button>
                                        <DragIcon
                                            dragHandleProps={draggableProvided.dragHandleProps}
                                        />
                                    </ContentBlock.Button>
                                    {canMoveDown && (
                                        <ContentBlock.Button>
                                            <Button
                                                bsStyle="link"
                                                onClick={() => move(index, index + 1)}
                                            >
                                                <i
                                                    aria-hidden="true"
                                                    className="fa fa-arrow-down"
                                                />
                                            </Button>
                                        </ContentBlock.Button>
                                    )}
                                </ContentBlock.ButtonGroup>
                            </ContentBlock.ActionSidebar>
                        </ContentBlock>
                    </Box>
                );
            }}
        </Draggable>
    );
};

DraggableChecklistSection.propTypes = {
    buildRoute: PropTypes.func.isRequired,
    canMoveDown: PropTypes.bool,
    canMoveUp: PropTypes.bool,
    draggable: PropTypes.object.isRequired,
    formErrors: PropTypes.object,
    index: PropTypes.number.isRequired,
    move: PropTypes.func.isRequired,
};
