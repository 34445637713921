import React, { useContext } from 'react';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';
import { projectTypesDict } from '@og-pro/shared-config/projects';

import { ProjectReviewChecklist, ContractReviewChecklist } from '../../components/review';
import { ProjectCreateV2FunctionsContext } from '../../context';

export const ProjectCreateV2Review = () => {
    const enableContractPackageCompiler = useFlags(FLAGS.ENABLE_CONTRACT_PACKAGE_COMPILER);
    const { project } = useContext(ProjectCreateV2FunctionsContext);

    return enableContractPackageCompiler && project.type === projectTypesDict.CONTRACT ? (
        <ContractReviewChecklist />
    ) : (
        <ProjectReviewChecklist />
    );
};
