import { capitalDesignTokens } from '@opengov/capital-mui-theme';

export const customFieldsModalContainerStyle = {
    bgcolor: 'background.paper',
    border: '1px #CED2D6 solid',
    borderRadius: 1,
    boxShadow: '0px 3px 5px rgba(19, 21, 23, 0.22)',
    display: 'flex',
    flexDirection: 'column',
    height: '500px',
    left: '50%',
    opacity: 1,
    overflow: 'hidden',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
};

export const customFieldsTileContainerStyle = {
    alignItems: 'center',
    border: '1px #DFE3E8 solid',
    borderRadius: 1,
    display: 'inline-flex',
    flexDirection: 'column',
    height: '128px',
    justifyContent: 'space-between',
    padding: 2,
    width: '100%',
};

export const customFieldsTileStyle = {
    color: capitalDesignTokens.semanticColors.foreground.primary,
};
