import { Box } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

import { ReviewSequencesListItem } from './ReviewSequencesListItem';
import {
    TemplateListHeader,
    TemplateListTimestampHeaders,
    ZeroState,
} from '../../../../components';

export const ReviewSequencesList = ({
    hasNoCreatedSequences,
    ReviewSequenceCreateButton,
    reviewSequences,
    routingHandler,
}) => {
    if (reviewSequences.length === 0) {
        const props = {
            button: hasNoCreatedSequences ? <ReviewSequenceCreateButton size="lg" /> : undefined,
            title: hasNoCreatedSequences ? 'No sequences created yet' : 'No sequences here!',
        };
        return <ZeroState {...props} useOpenGovStyle />;
    }

    return (
        <>
            <Box className="row hidden-xs hidden-sm" sx={{ padding: '0 16px 5px' }}>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-md-5">
                            <TemplateListHeader useOpenGovStyle>Name</TemplateListHeader>
                        </div>
                        <div className="col-md-3">
                            <TemplateListHeader useOpenGovStyle>Owner</TemplateListHeader>
                        </div>
                        <div className="col-md-4">
                            <TemplateListHeader useOpenGovStyle>Sequence In Use</TemplateListHeader>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <TemplateListTimestampHeaders useOpenGovStyle />
                </div>
            </Box>
            <ListGroup>
                {reviewSequences.map((reviewSequence) => {
                    return (
                        <ListGroupItem
                            key={reviewSequence.id}
                            onClick={() => routingHandler(reviewSequence.id)}
                        >
                            <ReviewSequencesListItem reviewSequence={reviewSequence} />
                        </ListGroupItem>
                    );
                })}
            </ListGroup>
        </>
    );
};

ReviewSequencesList.propTypes = {
    hasNoCreatedSequences: PropTypes.bool,
    ReviewSequenceCreateButton: PropTypes.func.isRequired,
    reviewSequences: PropTypes.array.isRequired,
    routingHandler: PropTypes.func.isRequired,
};
