import { Box, Chip, Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { statusTypes } from '@og-pro/shared-config/reviewSequences';

import { ReviewSequenceReviewGroups } from './ReviewSequenceReviewGroups';
import {
    TemplateListTimestampColumns,
    TemplateStatusLabel,
    UserProfilePicture,
} from '../../../../components';

const { ARCHIVED, PUBLISHED } = statusTypes;

export const ReviewSequencesListItem = ({
    reviewSequence: {
        created_at: createdAt,
        icon,
        isDefault,
        isException,
        lastUpdatedAt,
        name,
        requestTypeGroupSequences,
        status,
        user,
    },
}) => {
    return (
        <div className="row">
            <div className="col-md-9">
                <div className="row">
                    <div className="col-md-5">
                        <Typography component="div">
                            {icon && (
                                <Box
                                    className={`fa fa-fw fa-${icon}`}
                                    component="i"
                                    marginRight={0.5}
                                />
                            )}
                            {name || 'Untitled'}
                        </Typography>
                        <Box display="flex" gap={0.5} marginTop={0.5}>
                            <TemplateStatusLabel
                                isArchived={status === ARCHIVED}
                                isPublished={status === PUBLISHED}
                                useOpenGovStyle
                            />
                            {isDefault && (
                                <Chip
                                    color="primary"
                                    label="Default Sequence"
                                    size="extraSmall"
                                    variant="tag"
                                />
                            )}
                            {isException && (
                                <Chip
                                    color="warning"
                                    label="Exception"
                                    size="extraSmall"
                                    variant="tag"
                                />
                            )}
                        </Box>
                    </div>
                    <div className="col-md-3 hidden-xs hidden-sm">
                        {user && (
                            <>
                                <UserProfilePicture horizontal user={user} />
                                &nbsp;&nbsp;
                                <Typography component="span">{user.displayName}</Typography>
                            </>
                        )}
                    </div>
                    <div className="col-md-4 hidden-xs hidden-sm">
                        <ReviewSequenceReviewGroups
                            requestTypeGroupSequences={requestTypeGroupSequences}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-3 hidden-xs hidden-sm">
                <TemplateListTimestampColumns
                    createdAt={createdAt}
                    lastUpdatedAt={lastUpdatedAt}
                    useOpenGovStyle
                />
            </div>
        </div>
    );
};

ReviewSequencesListItem.propTypes = {
    reviewSequence: PropTypes.shape({
        created_at: PropTypes.string.isRequired,
        icon: PropTypes.string,
        isDefault: PropTypes.bool.isRequired,
        isException: PropTypes.bool.isRequired,
        name: PropTypes.string,
        lastUpdatedAt: PropTypes.string.isRequired,
        requestTypeGroupSequences: PropTypes.array.isRequired,
        status: PropTypes.number.isRequired,
        user: PropTypes.shape({
            displayName: PropTypes.string.isRequired,
        }),
    }).isRequired,
};
