import { createSelector } from 'reselect';

export const getRequisitionsAdminPath = (_state, { params }) => {
    const { governmentId } = params;
    return `/governments/${governmentId}/requisitions-admin`;
};

export const getCustomFieldsLibraryPath = createSelector(
    [getRequisitionsAdminPath],
    (requisitionsAdminPath) => {
        return `${requisitionsAdminPath}/custom-fields`;
    }
);
