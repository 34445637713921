import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { DateTime } from './DateTime';
import { timezoneAbbreviations } from '../../constants';
import { HelpBlock } from '..';

/**
 * Unfortunately `PureComponent` can't be used
 * here, since we don't want it to re-render every
 * time min changes. Basically,there's a bug in the
 * library where if you change min, then its ui stops
 * working... really annoying.
 */
export class DateTimePicker extends Component {
    static propTypes = {
        allowEmpty: PropTypes.bool,
        className: PropTypes.string,
        date: PropTypes.bool,
        dateFormat: PropTypes.string,
        disabled: PropTypes.bool,
        dropUp: PropTypes.bool,
        hasFeedback: PropTypes.bool,
        horizontal: PropTypes.bool,
        input: PropTypes.object.isRequired,
        label: PropTypes.string,
        meta: PropTypes.shape({
            error: PropTypes.string,
            touched: PropTypes.bool,
        }).isRequired,
        max: PropTypes.instanceOf(Date),
        min: PropTypes.instanceOf(Date),
        onChange: PropTypes.func,
        onInputDateChange: PropTypes.func,
        parse: PropTypes.func,
        placeholder: PropTypes.string,
        remove: PropTypes.func,
        showValidation: PropTypes.bool,
        time: PropTypes.bool,
        timezone: PropTypes.string,
        useOpenGovStyle: PropTypes.bool,
    };

    static defaultProps = {
        allowEmpty: false,
        className: undefined,
        date: true,
        dateFormat: undefined,
        disabled: false,
        dropUp: false,
        hasFeedback: true,
        horizontal: false,
        label: undefined,
        max: undefined,
        min: undefined,
        parse: undefined,
        remove: undefined,
        showValidation: false,
        time: undefined,
        timezone: undefined,
        useOpenGovStyle: false,
    };

    get styles() {
        return require('./DateTimePicker.scss');
    }

    renderDateInput() {
        const {
            allowEmpty,
            date,
            dateFormat,
            disabled,
            dropUp,
            horizontal,
            input,
            label,
            max,
            min,
            meta: { error, touched },
            onChange,
            onInputDateChange,
            parse,
            placeholder,
            showValidation,
            time,
            remove,
            useOpenGovStyle,
        } = this.props;

        const labelAsId = label && label.replace(/\s/g, '');
        const labelComponent = label ? (
            <label htmlFor={labelAsId} id={labelAsId}>
                {label}
            </label>
        ) : null;

        const info = (
            <div className={`col-sm-6 ${this.styles.horizontalLabel}`}>
                {remove && (
                    <div className={this.styles.remove}>
                        <i className="fa fa-times fa-lg" onClick={remove} />
                    </div>
                )}
                {labelComponent}
            </div>
        );

        const defaultDateOnlyFormat = 'M/D/YYYY';
        const defaultFormat = time ? `${defaultDateOnlyFormat} [at] h:mma` : defaultDateOnlyFormat;

        const dateTimeComponent = (
            <DateTime
                allowEmpty={allowEmpty}
                date={date}
                disabled={disabled}
                dropUp={dropUp}
                format={dateFormat || defaultFormat}
                input={input}
                label={label && !horizontal && labelAsId}
                max={max}
                min={min}
                onChange={onChange}
                onInputDateChange={onInputDateChange}
                parse={parse}
                placeholder={placeholder}
                time={time}
            />
        );
        const displayValidation = touched || showValidation;
        const errorMessage = error || null;

        const helpBlock = displayValidation && errorMessage && (
            <HelpBlock
                className={useOpenGovStyle && this.styles.openGovHelpBlock}
                useOpenGovStyle={useOpenGovStyle}
            >
                {useOpenGovStyle && (
                    <>
                        <i className="fa fa-exclamation-triangle" />
                        &nbsp;
                    </>
                )}
                {errorMessage}
            </HelpBlock>
        );
        const timezone = this.renderTimezone();

        if (horizontal) {
            const hasFormOptions = label || remove;

            return (
                <div className="row">
                    {hasFormOptions && info}
                    <div className={`col-sm-${hasFormOptions ? '6' : '12'}`}>
                        {dateTimeComponent}
                        {timezone}
                        {helpBlock}
                    </div>
                </div>
            );
        }

        return (
            <div>
                {labelComponent}
                {dateTimeComponent}
                {timezone}
                {helpBlock}
            </div>
        );
    }

    renderTimezone() {
        const { timezone } = this.props;

        if (!timezone) {
            return null;
        }

        return (
            <div className={`text-muted ${this.styles.timezone}`}>
                {timezoneAbbreviations[timezone]}
            </div>
        );
    }

    render() {
        const {
            className,
            hasFeedback,
            meta: { error, touched },
            showValidation,
            timezone,
            useOpenGovStyle,
        } = this.props;

        const displayValidation = touched || showValidation;

        let groupState = 'primary';
        if (displayValidation && error) {
            groupState = 'has-error';
        } else if (displayValidation && !useOpenGovStyle) {
            groupState = 'has-success';
        }

        return (
            <div
                className={classnames(
                    'form-group',
                    className,
                    timezone && this.styles.timezoneContainer,
                    hasFeedback && groupState
                )}
            >
                {this.renderDateInput()}
            </div>
        );
    }
}
