import React from 'react';
import { useParams } from 'react-router-dom';

import { FLAGS, useFlags } from '@og-pro/launch-darkly/client';

import { Box } from '@mui/material';

import { useSelector } from 'react-redux';

import { LoadingError, Main, NotFound, PageTitle } from '../../../../components';
import { RequisitionsCreateCustomFieldHeader } from './RequisitionsCreateCustomFieldHeader';

import { pageContainerStyles } from './styles';
import { isSystemAdminUser } from '../../../selectors';
import { hasRequisitionSubscription } from '../../../GovApp/selectors';
import { RequisitionsCreateCustomFieldBody } from './RequisitionsCreateCustomFieldBody';

export const RequisitionsCreateCustomField = () => {
    const params = useParams();
    const enableCustomFields = useFlags(FLAGS.ENABLE_CUSTOM_FIELDS);
    const isSystemAdmin = useSelector(isSystemAdminUser);
    const hasRequisitions = useSelector(hasRequisitionSubscription);

    if (!enableCustomFields) {
        return <NotFound />;
    }

    if (!isSystemAdmin || !hasRequisitions) {
        return (
            <LoadingError error="You do not have permission to view this page" useOpenGovStyle />
        );
    }

    return (
        <Main>
            <PageTitle title="Create New Custom Field" />
            <Box sx={pageContainerStyles}>
                <RequisitionsCreateCustomFieldHeader fieldType={params.fieldType} />
                <RequisitionsCreateCustomFieldBody />
            </Box>
        </Main>
    );
};
