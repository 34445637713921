import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Typography } from '@og-pro/ui';
import { fieldsDataTypes } from '@og-pro/shared-config/customFormService/customField';
import { useSelector } from 'react-redux';

import { getCustomFieldsLibraryPath } from '../selectors';

export const RequisitionsCreateCustomFieldHeader = ({ fieldType }) => {
    const params = useParams();
    const navigate = useNavigate();

    const customFieldsLibraryPath = useSelector((state) =>
        getCustomFieldsLibraryPath(state, { params })
    );

    function handleBackToLibraryClick() {
        navigate(customFieldsLibraryPath);
    }

    function handleCreateFieldClick() {
        // TODO: implement
    }

    function handleCreateAndPublishFieldClick() {
        // TODO: implement
    }

    return (
        <Box>
            <Box>
                <Button
                    color="secondary"
                    onClick={handleBackToLibraryClick}
                    qaTag="requisitionsAdminCustomFields-back"
                    startIcon={<ArrowBack />}
                    sx={{
                        padding: 0,
                    }}
                    variant="text"
                >
                    Back to Library of Custom Fields
                </Button>
            </Box>
            <Box alignItems="center" display="flex" justifyContent="space-between">
                <Box>
                    <Typography variant="h2">
                        Create a New {fieldsDataTypes[fieldType].label} Field
                    </Typography>
                </Box>
                <Box display="flex" gap={1}>
                    <Button
                        color="primary"
                        onClick={handleCreateAndPublishFieldClick}
                        qaTag="requisitionsAdminCustomFields-createAndPublish"
                        variant="outlined"
                    >
                        Create & Publish Field
                    </Button>
                    <Button
                        onClick={handleCreateFieldClick}
                        qaTag="requisitionsAdminCustomFields-create"
                        variant="contained"
                    >
                        Create Field
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

RequisitionsCreateCustomFieldHeader.propTypes = {
    fieldType: PropTypes.oneOf(Object.keys(fieldsDataTypes)).isRequired,
};
