import { Chip } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';

import { StatusLabel } from '../..';

export const TemplateStatusLabel = ({
    className,
    isArchived,
    isPublished,
    isSpecial,
    isArchivedLabelStyle,
    useOpenGovStyle,
}) => {
    const getStatusData = () => {
        const defaultArchivedStyle = useOpenGovStyle ? 'error' : 'danger';
        if (isArchived) return [isArchivedLabelStyle || defaultArchivedStyle, 'Archived'];
        if (isSpecial) return ['primary', 'Special'];
        if (isPublished) return ['success', 'Live'];
        return ['default', 'Draft'];
    };

    const [color, label] = getStatusData();

    if (useOpenGovStyle) {
        return (
            <Chip
                className={className}
                color={color}
                label={label}
                size="extraSmall"
                variant="tag"
            />
        );
    }

    return (
        <StatusLabel bsStyle={color} className={className}>
            {label}
        </StatusLabel>
    );
};

TemplateStatusLabel.propTypes = {
    className: PropTypes.string,
    isArchived: PropTypes.bool,
    isArchivedLabelStyle: PropTypes.string,
    isPublished: PropTypes.bool.isRequired,
    isSpecial: PropTypes.bool,
    useOpenGovStyle: PropTypes.bool,
};
