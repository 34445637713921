import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Alert, Panel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Outlet } from 'react-router-dom';
import { withRouter } from '@og-pro-migration-tools/react-router';
import { NoSsr } from '@mui/material';

import { projectStatusesDict } from '@og-pro/shared-config/projects';

import { AssociatedProjects, DisplayItems } from '../components';
import { ReportsModalButton } from '../..';
import {
    getBaseProjectPath,
    getBidPosterAuthorized,
    getProjectJS,
    hasRequisitionSubscription,
    isDocumentEditor,
} from '../../selectors';
import {
    Button,
    CloseDateButton,
    ConnectedClients,
    LoadingError,
    Main,
    ProjectRequisitionsDisplay,
    ProjectRetentionPolicyButton,
    ProjectStatusLabel,
    SubscribeButton,
} from '../../../../components';
import { PreInviteModal } from '../PreInviteModal';
import { getProjectContracts } from '../selectors';
import { isRetentionAdminUser } from '../../../selectors';
import { ProjectHoldStatus } from '../../../../components/ProjectHoldStatus';

const { CLOSED } = projectStatusesDict;

const mapStateToProps = (state, props) => {
    return {
        contracts: getProjectContracts(state),
        hasBidPostAuthorization: getBidPosterAuthorized(state),
        hasRequisitions: hasRequisitionSubscription(state),
        isRetentionAdmin: isRetentionAdminUser(state),
        isUserDocumentEditor: isDocumentEditor(state),
        project: getProjectJS(state),
        projectPath: getBaseProjectPath(state, props),
    };
};

// @connect
class ConnectedProjectDashboardContainer extends Component {
    static propTypes = {
        contracts: PropTypes.array,
        hasBidPostAuthorization: PropTypes.bool.isRequired,
        hasRequisitions: PropTypes.bool.isRequired,
        isRetentionAdmin: PropTypes.bool.isRequired,
        isUserDocumentEditor: PropTypes.bool.isRequired,
        project: PropTypes.shape({
            closeOutReason: PropTypes.string,
            departmentName: PropTypes.string,
            financialId: PropTypes.string,
            id: PropTypes.number,
            isPaused: PropTypes.bool.isRequired,
            isPausedReason: PropTypes.string,
            isPrivate: PropTypes.bool.isRequired,
            project_association_id: PropTypes.number,
            recordCloseDate: PropTypes.string,
            retention_code_id: PropTypes.number,
            status: PropTypes.string.isRequired,
            template: PropTypes.shape({
                icon: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
            }).isRequired,
            title: PropTypes.string,
        }),
        projectPath: PropTypes.string.isRequired,
    };

    get styles() {
        return require('./index.scss');
    }

    componentDidMount() {
        window.scroll(0, 0);
    }

    render() {
        const {
            contracts,
            hasBidPostAuthorization,
            hasRequisitions,
            isRetentionAdmin,
            isUserDocumentEditor,
            project,
            project: {
                closeOutReason,
                departmentName,
                financialId,
                id,
                isPaused,
                isPausedReason,
                isPrivate,
                recordCloseDate,
                retention_code_id: retentionCodeId,
                status,
                template,
            },
            projectPath,
        } = this.props;

        if (!project) {
            return <LoadingError />;
        }

        const hasContracts = contracts && contracts.length > 0;
        const projectClosed = status === CLOSED;
        const showCloseDateButton = projectClosed && hasBidPostAuthorization;
        const showRetentionPolicyButton = projectClosed && isRetentionAdmin;

        return (
            <Main className="row">
                <div className="col-lg-offset-1 col-lg-10">
                    <ConnectedClients block />
                    <NoSsr>
                        <PreInviteModal />
                    </NoSsr>
                    <Panel>
                        <Panel.Body>
                            {isPrivate && (
                                <Alert bsStyle="info" className={this.styles.alert}>
                                    <i className="fa fa-lock" /> Private Bid (not displayed on
                                    public portal)
                                </Alert>
                            )}
                            <ProjectHoldStatus project={{ isPaused, isPausedReason }} />
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className={this.styles.subscribeButtonContainer}>
                                        <ReportsModalButton
                                            bsSize="sm"
                                            className={this.styles.reportsButton}
                                            text="Reports"
                                        />
                                        &nbsp;&nbsp;
                                        <SubscribeButton isGovernment project={project} />
                                    </div>
                                    <div className={this.styles.projectTitle}>
                                        <h3>{project.title || 'Untitled'}</h3>
                                        <div>
                                            <ProjectStatusLabel
                                                className={this.styles.statusLabel}
                                                status={project.status}
                                            />
                                            <Button
                                                bsStyle="link"
                                                qaTag="connectedProjectDashboardContainer-statusHistory"
                                                to={`${projectPath}/status-history`}
                                                zeroPadding
                                            >
                                                <i className="fa fa-history" /> Status History
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classnames('row', this.styles.details)}>
                                <div className="col-xs-12">
                                    <DisplayItems
                                        items={[
                                            { label: 'Template', value: template.title },
                                            { label: 'Department', value: departmentName },
                                            { label: 'Project ID', value: financialId },
                                            {
                                                label: 'Associated Projects',
                                                value: project.project_association_id && (
                                                    <AssociatedProjects />
                                                ),
                                            },
                                            {
                                                label: 'Requisition(s)',
                                                value: hasRequisitions && (
                                                    <ProjectRequisitionsDisplay
                                                        isEditor={isUserDocumentEditor}
                                                        projectId={project.id}
                                                    />
                                                ),
                                            },
                                            {
                                                label: 'Project Close Date',
                                                value: showCloseDateButton && (
                                                    <CloseDateButton
                                                        canEdit={!hasContracts}
                                                        closeDate={recordCloseDate}
                                                        projectId={id}
                                                    />
                                                ),
                                            },
                                            {
                                                label: 'Retention Policy',
                                                value: showRetentionPolicyButton && (
                                                    <ProjectRetentionPolicyButton
                                                        canEdit={!hasContracts}
                                                        retentionCodeId={retentionCodeId}
                                                    />
                                                ),
                                            },
                                            { label: 'Closed Out', value: closeOutReason },
                                        ]}
                                    />
                                </div>
                            </div>
                            <Outlet />
                        </Panel.Body>
                    </Panel>
                </div>
            </Main>
        );
    }
}

export const ProjectDashboardContainer = compose(
    withRouter,
    connect(mapStateToProps)
)(ConnectedProjectDashboardContainer);
