import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider as Mui5ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import { capitalMuiTheme } from '@opengov/capital-mui-theme';
import { asyncWithLDProvider, formatUserForLd, isDevMode } from '@og-pro/launch-darkly/client';

import { Auth0Provider } from '@auth0/auth0-react';

import { getMetaTitle } from './helpers';
import rollbar from './lib/rollbar';
import { getStore } from './store';
import { getRoutes } from '../config';

import createEmotionCache from '../server/createEmotionCache';

const cache = createEmotionCache();

(async () => {
    // Initialize rollbar client module on the global window
    window.Rollbar = rollbar;

    // https://www.ag-grid.com/javascript-grid-set-license/
    LicenseManager.setLicenseKey(
        'CompanyName=OpenGov, Inc.,LicensedApplication=Govlist,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=9,LicensedProductionInstancesCount=2,AssetReference=AG-039305,SupportServicesEnd=29_July_2024_[v2]_MTcyMjIwNzYwMDAwMA==20ec3a569b4529091311ab6acc4095d4'
    );

    const store = getStore({ newStore: true });

    const user = store.getState().auth.get('user')?.toJS();

    let LDProvider;

    const routes = getRoutes(store.getState, store.dispatch);
    const router = createBrowserRouter(routes);

    if (!isDevMode) {
        // Initialize LaunchDarkly with user, if one exists, or anonymous user. Async is used to prevent app flicker:
        // https://docs.launchdarkly.com/sdk/client-side/react/react-web#initializing-asyncwithldprovider
        LDProvider = await asyncWithLDProvider({
            clientSideID: process.env.LAUNCH_DARKLY_CLIENTSIDE_ID,
            context: formatUserForLd(user),
            reactOptions: {
                useCamelCaseFlagKeys: false,
            },
        });
    }

    // eslint-disable-next-line react/prop-types
    const LaunchDarklyProvider = ({ children }) => {
        if (LDProvider) {
            return <LDProvider>{children}</LDProvider>;
        }

        return children;
    };

    const queryClient = new QueryClient();

    // TODO Update with the new React 18 hydrateRoot, but for now, we'll keep it as is.
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.hydrate(
        <Provider key="provider" store={store}>
            <Auth0Provider
                authorizationParams={{
                    redirect_uri: `${process.env.SITE_URL}/login`,
                    audience: 'opengov',
                }}
                clientId={process.env.AUTH0_UI_CLIENT_ID}
                domain={process.env.AUTH0_URL}
            >
                <LaunchDarklyProvider>
                    <CacheProvider value={cache}>
                        <Mui5ThemeProvider theme={capitalMuiTheme}>
                            <HelmetProvider>
                                <Helmet>
                                    <title>{getMetaTitle('OpenGov Procurement')}</title>
                                </Helmet>
                                <QueryClientProvider client={queryClient}>
                                    <RouterProvider router={router} />
                                    {__DEV__ && <ReactQueryDevtools initialIsOpen={false} />}
                                </QueryClientProvider>
                            </HelmetProvider>
                        </Mui5ThemeProvider>
                    </CacheProvider>
                </LaunchDarklyProvider>
            </Auth0Provider>
        </Provider>,
        document.getElementById('root')
    );
})();
