import { createSelector } from 'reselect';

const requisitionsAdminNavItems = [
    {
        path: '/settings',
        title: 'Settings',
    },
    {
        path: '/entity-positions',
        title: 'Entity Positions',
    },
    {
        path: '/group-positions',
        title: 'Group Positions',
    },
    {
        path: '/flags',
        title: 'Flags',
    },
    {
        path: '/custom-fields',
        title: 'Custom Fields',
    },
];

const getSelectedRequisitionsAdminNavItem = createSelector(
    [(state, props) => props.location.pathname],
    (pathname) => {
        return (
            requisitionsAdminNavItems.find((item) => !!item.path && pathname.match(item.path)) ||
            requisitionsAdminNavItems[0]
        );
    }
);

export const getRequisitionsAdminNavItems = createSelector(
    [getSelectedRequisitionsAdminNavItem, (state, props) => props.enableCustomFields],
    // Add Flag to enable/disable custom fields
    (selectedNavItem, enableCustomFields) => {
        let navItems = [...requisitionsAdminNavItems];

        if (!enableCustomFields) {
            navItems = navItems.filter((item) => item.path !== '/custom-fields');
        }

        return navItems.map((navItem) => {
            return {
                ...navItem,
                isActive: navItem.title === selectedNavItem.title,
            };
        });
    }
);
