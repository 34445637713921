import { capitalDesignTokens } from '@opengov/capital-mui-theme';

// This one can be used across several Requisitions pages header, I am not sure if it is the best place to put it
export const requisitionsPageTitleContainerStyle = {
    backgroundColor: capitalDesignTokens.foundations.colors.gray50,
    borderBottom: `1px solid ${capitalDesignTokens.foundations.colors.gray200}`,
    mt: '-20px',
    ml: '-15px',
    mr: '-15px',
    pt: 2,
    pr: 8,
    pb: 0,
    pl: 8,
    gap: 0.5,
};
