const SHORT_TEXT = 'shortText';
const LONG_TEXT = 'longText';
const NUMBER = 'number';
const DATE = 'date';
const DROPDOWN = 'dropdown';
const CHECKBOX = 'checkbox';
const YES_NO = 'yesNo';
const MULTIPLE_CHOICE = 'multipleChoice';

exports.fieldsTypes = {
    SHORT_TEXT,
    LONG_TEXT,
    NUMBER,
    DATE,
    DROPDOWN,
    CHECKBOX,
    YES_NO,
    MULTIPLE_CHOICE,
};

exports.fieldsTypesArray = [
    SHORT_TEXT,
    LONG_TEXT,
    NUMBER,
    YES_NO,
    MULTIPLE_CHOICE,
    DATE,
    DROPDOWN,
    CHECKBOX,
];

// Icon is a placeholder for now
exports.fieldsDataTypes = {
    [SHORT_TEXT]: {
        label: 'Short Text',
        icon: 'short text',
    },
    [LONG_TEXT]: {
        label: 'Long Text',
        icon: 'long text',
    },
    [NUMBER]: {
        label: 'Number',
        icon: 'number',
    },
    [YES_NO]: {
        label: 'Yes/No',
        icon: 'yesno',
    },
    [MULTIPLE_CHOICE]: {
        label: 'Multiple Choice',
        icon: 'multiplechoice',
    },
    [DATE]: {
        label: 'Date',
        icon: 'date',
    },
    [DROPDOWN]: {
        label: 'Dropdown',
        icon: 'dropdown',
    },
    [CHECKBOX]: {
        label: 'Checkbox',
        icon: 'checkbox',
    },
};

const dataTypes = {
    STRING: 'string',
    SINGLE_ENUM: 'singleEnum',
    MULTI_ENUM: 'multiEnum',
    NUMBER: 'number',
    BOOLEAN: 'boolean',
    DATE: 'date',
};

exports.dataTypes = dataTypes;

exports.allowedDataTypeMap = {
    [SHORT_TEXT]: [dataTypes.STRING],
    [LONG_TEXT]: [dataTypes.STRING],
    [NUMBER]: [dataTypes.NUMBER],
    [DATE]: [dataTypes.DATE],
    [DROPDOWN]: [dataTypes.SINGLE_ENUM],
    [CHECKBOX]: [dataTypes.BOOLEAN],
    [YES_NO]: [dataTypes.SINGLE_ENUM],
    [MULTIPLE_CHOICE]: [dataTypes.SINGLE_ENUM, dataTypes.MULTI_ENUM],
};

exports.characterLimits = [256, 512, 1200, 5000];

exports.instructionsModes = {
    DESCRIPTION: 'description',
    TOOLTIP: 'tooltip',
};

exports.numberKinds = {
    DECIMAL: 'decimal',
    WHOLE: 'whole',
    PERCENTAGE: 'percentage',
    CURRENCY: 'currency',
};
