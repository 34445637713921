import { Typography } from '@og-pro/ui';
import PropTypes from 'prop-types';
import React from 'react';

export const ReviewSequenceReviewGroups = ({ requestTypeGroupSequences }) => {
    return requestTypeGroupSequences.map(({ reviewGroup: { icon, id, name } }) => (
        <Typography component="div" key={id}>
            {icon && (
                <>
                    <i className={`fa fa-fw fa-${icon}`} />
                    &nbsp;
                </>
            )}
            {name || 'Untitled'}
        </Typography>
    ));
};

ReviewSequenceReviewGroups.propTypes = {
    requestTypeGroupSequences: PropTypes.arrayOf(
        PropTypes.shape({
            reviewGroup: PropTypes.shape({
                icon: PropTypes.string,
                id: PropTypes.number.isRequired,
                name: PropTypes.string,
            }),
        })
    ).isRequired,
};
